<template>
  <!--服务商单量统计-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="88px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <el-col :lg="6" :md="12">
                  <el-form-item label="服务商:">
                    <el-input
                        clearable
                        v-model="where.facilitator_name"
                        placeholder="请输入服务商名称"/>
                  </el-form-item>
                </el-col>
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="服务区域:">-->
                <!--    <el-cascader-->
                <!--      ref="refCascader"-->
                <!--      :options="city"-->
                <!--      v-model="where.serve_district"-->
                <!--      @change="handleChange"></el-cascader>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="下单时间:">-->
                <!--    <el-date-picker-->
                <!--        v-model="where.value1"-->
                <!--        type="daterange"-->
                <!--        range-separator="至"-->
                <!--        start-placeholder="开始日期"-->
                <!--        end-placeholder="结束日期">-->
                <!--    </el-date-picker>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :lg="6" :md="12">
                  <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                  </el-button>
                  <el-button @click="empty">清空</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <!--<div class="custom" style="margin-left: 20px">-->
              <!--  <el-button class="button" type="primary">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-tianjia" />-->
              <!--    <span style="vertical-align: middle" @click="openEdit(null)">添加</span>-->
              <!--  </el-button>-->
              <!--  <el-button class="custom-button">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-devops" />-->
              <!--    <span style="vertical-align: middle">批量导入</span>-->
              <!--  </el-button>-->
              <!--</div>-->
            </div>
            <!--<div>-->
            <!--  <el-button>导出数据</el-button>-->
            <!--  <el-button @click="ListSetup()">列表设置</el-button>-->
            <!--</div>-->
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {
  getcityList, otheroutput_statistics,
} from '@/api/yunli'


export default {
  // 组件生效
  components: {
    PackageSortElement,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "编号",
        //   // type: "index",
        //   // width: "70"
        //   prop: "id",
        //   isShow: true,
        //   fixed: 'left'
        // },
        {
          label: "名称",
          // type: "index",
          // width: "70"
          prop: "company_name",
          isShow: true,
        },
        {
          label: "服务司机",
          prop: "driver_count",
          isShow: true
        },
        {
          label: "服务车辆",
          prop: "vehicle_count",
          isShow: true
        },
        {
          label: "总单量",
          prop: "order_total",
          isShow: true
        },
        {
          label: "H5",
          prop: "order_h5_count",
          isShow: true
        },
        {
          label: "小程序",
          prop: "order_applet_count",
          isShow: true
        },
        {
          label: "APP",
          prop: "order_app_count",
          isShow: true
        },
        {
          label: "议价",
          prop: "order_bargain_price",
          isShow: true
        },
        {
          label: "拒单",
          prop: "order_receiving",
          isShow: true
        },
        {
          label: "催单",
          prop: "order_urge",
          isShow: true
        },
        {
          label: "催单率",
          prop: "order_urge_rate",
          isShow: true
        },
        {
          label: "结算金额",
          prop: "order_actual_payment",
          isShow: true
        },
        {
          label: "投诉单量",
          prop: "order_complaint",
          isShow: true
        },
        {
          label: "投诉率",
          prop: "order_complaint_rate",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //城市列表
      city:[],

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
    //获取城市列表
    this.getCity();
  },

  methods:{
    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    // 获取列表
    getList(){
      otheroutput_statistics(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //级联选择器
    handleChange(value) {
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + '|' + areaTextArr[0];
      let city = value[1] + '|' + areaTextArr[1];
      let district = value[2] + '|' + areaTextArr[2];
      area.push(province,city,district);
      console.log(area)
    },


  }

}
</script>

<style lang="scss" scoped>

</style>
